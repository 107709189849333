import { faRedo } from "@fortawesome/pro-light-svg-icons";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEcsSessionEvents } from "../../../services/ybrServices";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithoutSelection from "../../common/table/TableWithoutSelection";
import { KeyValue } from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";

type EcsEventsProps = {
  id: string;
  session: string;
};

const EcsEvents: React.FC<WithAlertModalProps> = ({ onUnexpectedError }) => {
  const params = useParams<EcsEventsProps>();
  const [events, setEvents] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const session = params.session!;
  const systemId = parseInt(params.id!);

  useEffect(() => {
    setLoading(true);
    getEcsSessionEvents(session)
      .then((evs: Array<any>) => {
        setEvents(evs);
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
      });
  }, [onUnexpectedError, session, systemId, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols: Array<KeyValue<any>> = [
    {
      key: "event_title",
      title: "Task Name",
      toValue: (s) => {
        return <p>{s.event_title}</p>;
      },
    },
    {
      key: "status",
      title: "Status",
    },
    {
      key: "timestamp",
      title: "Timestamp",
      toValue: (s) => {
        return <p>{dayjs(s.timestamp).format()} </p>;
      },
    },
    {
      key: "output",
      title: "Output",
      toValue: (s) => {
        const stdout = s.output.stdout_lines?.map((line: string) => {
          return line + "\n";
        });
        const stderr = s.output.stderr_lines?.map((line: string) => {
          return line + "\n";
        });

        return (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {stdout?.length > 0 && <code>{stdout}</code>}
            {stderr?.length > 0 && (
              <code>
                <pre>{stderr}</pre>
              </code>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <ModernCrumbar
        primary="ECS Sync History"
        primaryPath={`/admin/integrations/${systemId}/ecsSync`}
        secondary={`ECS Sync Events for Session "${session ?? "?"}"`}
      />
      <LoadilyFadily loaded={!loading} className="jh-page-layout">
        <ActionBar rightButtonProps={buttonProps} />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithoutSelection
            columns={tableCols}
            rows={events}
            sortColumn="timestamp"
          />
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(EcsEvents);
