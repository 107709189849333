import {
  InsightDefinition,
  InsightDto,
  InsightEntity,
  Unit,
  UnitOf,
  isUnitOfCountOfTotal,
} from "@joyhub-integration/shared";

import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";

// you desire instant data to be greater than a min goal and less than a max goal
export type GoalType = "min" | "max";

// what the frontend currently expects
export type FrontEndUnit =
  | "Number"
  | "Percent"
  | "Dollar"
  | "Day"
  | "Month"
  | "Sqft"
  | "Year"
  | "NoValue";

export type KnownInsight = InsightDefinition & {
  primitiveUnit: FrontEndUnit;
};

// converts real units to what the frontend currently expects
// defaults to "Number" for anything more complex than CountOfTotal
const primitiveUnit = (u: UnitOf<any>): FrontEndUnit => {
  const u0: Unit = Unit.includesValue(u)
    ? u
    : isUnitOfCountOfTotal(u)
      ? u.count
      : "Number";
  const top: FrontEndUnit = u0 === "Dollar-Dollar" ? "Dollar" : u0;
  return Unit.includesValue(u) || (isUnitOfCountOfTotal(u) && top !== u.total)
    ? top
    : "Percent";
};

type KnownInsightsResponse = {
  insights: Array<InsightDefinition>;
};

export async function getKnownInsights(): Promise<Array<KnownInsight>> {
  return axios
    .get<KnownInsightsResponse>(apiUrl("/insights/known"), axiosConfig)
    .then((res) =>
      res.data.insights.map((insight) => ({
        primitiveUnit: primitiveUnit(insight.unit),
        ...insight,
      })),
    );
}

type GetInsightsResponse = {
  insights: Array<InsightEntity>;
};

export async function getCustomInsights(): Promise<Array<InsightEntity>> {
  return axios
    .get<GetInsightsResponse>(apiUrl("/insights"), axiosConfig)
    .then((res) => res.data.insights);
}

export async function getCustomInsight(id: number): Promise<InsightEntity> {
  return axios
    .get<InsightEntity>(apiUrl(`/insights/${id}`), axiosConfig)
    .then((res) => res.data);
}

export async function deleteCustomInsight(id: number): Promise<void> {
  return axios.delete(apiUrl(`/insights/${id}`), axiosConfig);
}

export async function postCustomInsight(
  insight: InsightDto,
): Promise<InsightEntity> {
  return axios
    .post<InsightEntity>(apiUrl("/insights"), insight, axiosConfig)
    .then((res) => res.data);
}

export async function putCustomInsight(
  id: number,
  insight: InsightDto,
): Promise<InsightEntity> {
  return axios
    .put<InsightEntity>(apiUrl(`/insights/${id}`), insight, axiosConfig)
    .then((res) => res.data);
}
