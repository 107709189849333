import React, { useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { getPlatform } from "../../services/platformService";
import { getSessionOrganization } from "../../services/sessionOrganization";
import {
  getSessionReturnUrl,
  setOrganization,
} from "../../services/sessionService";
import Loading from "../app/Loading";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";

const LoggedIn: React.FC<WithAlertModalProps> = ({ onUnexpectedError }) => {
  const { platform, setPlatform } = useContext(PlatformContext);
  const { person, organization } = platform!;
  useEffect(() => {
    try {
      window.analytics.identify(person.id.toString(), {
        name: person.name,
        email: person.email,
      });
    } catch (e) {
      console.error("Unable to fire segment identification event.", e);
    }
  }, [person.id, person.name, person.email]);
  // Logging into a different organization really only affects JoyHub users but we matter.
  const redirectUrl = getSessionReturnUrl() ?? "/";
  const sessionOrg = getSessionOrganization();
  // If we have just logged in and the organization doesn't match the organization we
  // were previously on (think session timeout and relogin) then switch back to that organization
  const organizationMatch =
    sessionOrg === null || sessionOrg === organization.id;
  useEffect(() => {
    if (sessionOrg !== null && !organizationMatch) {
      setOrganization(sessionOrg)
        .then(getPlatform)
        .then(setPlatform)
        .catch(onUnexpectedError);
    }
  }, [
    sessionOrg,
    organizationMatch,
    redirectUrl,
    setPlatform,
    onUnexpectedError,
  ]);
  return organizationMatch ? (
    <Navigate to={redirectUrl} replace={true} />
  ) : (
    <Loading />
  );
};

export default withAlertModal(LoggedIn);
