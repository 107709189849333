export const ruleSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  $ref: "#/definitions/AdHocRule",
  options: {
    disable_collapse: true,
    disable_edit_json: false,
  },

  definitions: {
    AdHocRule: {
      title: "Rule Type",
      anyOf: [
        {
          title: "By Property Rule",
          $ref: "#/definitions/ByPropertyRule",
        },
        {
          title: "Transformer Rule",
          $ref: "#/definitions/TransformerRule",
        },
      ],
    },

    ByPropertyRule: {
      type: "object",
      additionalProperties: false,
      properties: {
        match: {
          $ref: "#/definitions/AdHocRuleMatch",
        },
        dataDate: {
          title: "Data Date",
          type: "object",
          additionalProperties: false,
          properties: {
            fileNameRegex: {
              title: "File Name Match",
              type: "string",
              description:
                "First matching group; e.g. As of (\\d{2}-\\d{2}-\\d{4})",
            },
          },
          options: {
            disable_collapse: true,
          },
        },
        headerRow: {
          title: "Header Row Type",
          anyOf: [
            {
              $ref: "#/definitions/RowMatchFixed",
            },
            {
              $ref: "#/definitions/RowMatchContaining",
            },
          ],
          options: {
            disable_collapse: true,
          },
        },
        propertyColumn: {
          title: "Property Column Type",
          $ref: "#/definitions/ColumnMatch",
          options: {
            disable_collapse: true,
          },
        },
        insightColumns: {
          title: "Insight Columns",
          type: "array",
          items: {
            title: "Column",
            type: "object",
            additionalProperties: false,
            properties: {
              column: {
                title: "Column Type",
                $ref: "#/definitions/ColumnMatch",
              },
              insight: {
                title: "Insight Name",
                type: "string",
              },
            },
            required: ["column", "insight"],
            options: {
              disable_collapse: true,
            },
          },
          options: {
            disable_collapse: true,
          },
        },
      },
      required: ["match", "dataDate", "propertyColumn", "insightColumns"],
      options: {
        disable_collapse: true,
      },
    },

    RowMatchFixed: {
      title: "Fixed Row",
      type: "object",
      additionalProperties: false,
      properties: {
        row: {
          title: "Row Number",
          $ref: "#/definitions/RowNumber",
        },
      },
      required: ["row"],
      options: {
        disable_collapse: true,
      },
    },

    RowMatchContaining: {
      title: "Matching Row",
      type: "object",
      additionalProperties: false,
      properties: {
        cells: {
          title: "Matching Cells",
          type: "array",
          items: {
            type: "object",
            properties: {
              col: {
                title: "Column Letter",
                $ref: "#/definitions/ColumnLetter",
              },
              value: {
                type: "string",
              },
            },
            required: ["value"],
          },
          options: {
            disable_collapse: true,
          },
        },
      },
      required: ["cells"],
      options: {
        disable_collapse: true,
      },
    },

    ColumnMatch: {
      anyOf: [
        {
          $ref: "#/definitions/ColumnMatchFixed",
        },
        {
          $ref: "#/definitions/ColumnMatchHeader",
        },
      ],
    },

    ColumnMatchFixed: {
      title: "Fixed Column",
      type: "object",
      additionalProperties: false,
      properties: {
        col: {
          title: "Column Letter",
          $ref: "#/definitions/ColumnLetter",
        },
      },
      required: ["col"],
      options: {
        disable_collapse: true,
      },
    },

    ColumnMatchHeader: {
      title: "Header Column",
      type: "object",
      additionalProperties: false,
      properties: {
        header: {
          title: "Header Label",
          type: "string",
        },
      },
      required: ["header"],
      options: {
        disable_collapse: true,
      },
    },

    ColumnLetter: {
      type: "string",
      pattern: "^[A-Z]+$",
      default: "A",
    },

    RowNumber: {
      type: "number",
      minimum: 1,
      default: 1,
    },

    AdHocRuleMatch: {
      title: "Matching Conditions",
      type: "object",
      properties: {
        fileName: {
          title: "File Name Match",
          type: "string",
        },
        sheetName: {
          title: "Sheet Name Match",
          type: "string",
        },
        cells: {
          title: "Cell Value Match",
          type: "array",
          items: {
            title: "Cell",
            type: "object",
            properties: {
              col: {
                title: "Column Letter",
                $ref: "#/definitions/ColumnLetter",
              },
              row: {
                title: "Row Number",
                $ref: "#/definitions/RowNumber",
              },
              value: {
                title: "Cell Value",
                type: "string",
              },
            },
            required: ["col", "row", "value"],
            options: {
              disable_collapse: true,
            },
          },
          options: {
            disable_collapse: true,
          },
        },
      },
      required: ["fileName"],
      options: {
        disable_collapse: true,
      },
    },

    TransformerRule: {
      type: "object",
      additionalProperties: false,
      properties: {
        match: {
          $ref: "#/definitions/AdHocRuleMatch",
        },
        inputExcelConfigurations: {
          type: "array",
          items: {
            type: "object",
            properties: {
              startRow: { type: "integer", minimum: 1, default: 1 },
              endRow: {
                type: ["integer", "null"],
                minimum: 1,
                default: 1,
              },
              outputDataSetName: { type: "string" },
              includeHeaderInOutput: { type: "boolean" },
            },
            required: [
              "endRow",
              "startRow",
              "outputDataSetName",
              "includeHeaderInOutput",
            ],
          },
        },
        dataSetConfigs: {
          type: "array",
          items: {
            type: "object",
            properties: {
              name: { type: "string" },
              outputColumns: {
                type: "array",
                items: {
                  type: "object",
                  properties: {
                    outputColumnName: { type: "string" },
                    arguments: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          alias: { type: "string" },
                          inputColumn: { type: "string", default: "A" },
                        },
                        required: ["alias", "inputColumn"],
                      },
                    },
                    calculationFunction: { type: "string" },
                    displayFormat: {
                      type: "object",
                      properties: {
                        format: {
                          type: "string",
                          enum: [
                            "0.00",
                            '"$"#,##0.00',
                            '_("$"* #,##0.00_)',
                            "0.00%",
                            "# ?/?",
                            "0.00E+00",
                          ],
                        },
                      },
                    },
                  },
                  required: [
                    "outputColumnName",
                    "arguments",
                    "calculationFunction",
                  ],
                },
              },
            },
            required: ["name", "outputColumns"],
          },
        },
      },
      required: ["match", "inputExcelConfigurations", "dataSetConfigs"],
    },
  },
};
