import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { isPropertiesById } from "@joyhub-integration/shared";

import { InsightCustomComponentProps } from "../../services/models";
import { apiUrl } from "../../utils/api";
import { usePlatformContext } from "../app/PlatformContext";

type IndependentImageInsightCardConfig = {
  isPropertyImage?: string;
  imageGuid?: string;
};

export const IndependentImageInsightCard: React.FC<
  InsightCustomComponentProps<IndependentImageInsightCardConfig>
> = ({ dashboardInsight, selection }) => {
  const { isPropertyImage, imageGuid } = dashboardInsight?.stuff!;

  const solePropertyId = isPropertiesById(selection) ? selection : undefined;
  const propertiesMap = usePlatformContext().platform?.propertiesMap;

  const [imageSrc, setImageSrc] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    const propertyImageGuid =
      isPropertyImage &&
      solePropertyId &&
      propertiesMap?.[solePropertyId]?.image_guid;

    const imageUrl = propertyImageGuid
      ? apiUrl(`/properties/${solePropertyId}/image/${propertyImageGuid}`)
      : imageGuid
        ? apiUrl(`/dashboards/image/${imageGuid}`)
        : "";

    const match = location.pathname.match(/\/embed\/(.*)/);
    const isEmbedLink = match && match[1];

    setImageSrc(
      `${imageUrl}${isEmbedLink && imageUrl ? `?embedUUID=${match[1]}` : ""}`,
    );
  }, [imageGuid, isPropertyImage, location, propertiesMap, solePropertyId]);

  return imageSrc ? (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <img
        style={{ borderRadius: 5, maxWidth: "100%", maxHeight: "100%" }}
        src={imageSrc}
        alt=""
      />
    </div>
  ) : (
    <div>No Image</div>
  );
};
