import "./layout.scss";

import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

import { setSessionOrganization } from "../../services/sessionOrganization";
import { setOrganization } from "../../services/sessionService";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import OrgLogo from "./OrgLogo";

const OrgSwitcher: React.FC<{ modern?: boolean } & WithAlertModalProps> = ({
  modern,
  onUnexpectedError,
}) => {
  const { platform } = useContext(PlatformContext);
  const { organizations } = platform!;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggle = (e: any) => {
    setDropdownOpen((prevState) => !prevState);
    e.stopPropagation();
  };

  const pickOrganization = (id: number) => {
    setSessionOrganization(null); // so i can make the call to switch
    setOrganization(id)
      //.then(getPlatform)
      //.then(setPlatform)
      //.then(() => history.push("/")) // there's some state somewhere that causes problems...
      .then(() => (document.location.href = "/"))
      .catch(onUnexpectedError);
  };

  const filteredOrgs = useMemo(
    () =>
      sortBy(organizations, "name").filter((o) =>
        o.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [searchQuery, organizations],
  );

  return organizations.length === 1 ? (
    modern ? (
      <div className="navbar-brand">
        <OrgLogo modern />
      </div>
    ) : (
      <div className="mx-3 org-logo mw-100">
        <Link to="/">
          <OrgLogo />
        </Link>
      </div>
    )
  ) : (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={toggle}
      className={modern ? "" : "org-logo mw-100"}
    >
      <DropdownToggle
        caret
        color="transparent"
        className="navbar-brand p-0 d-flex align-items-center"
      >
        <OrgLogo modern={modern} />
      </DropdownToggle>
      <DropdownMenu style={{ maxWidth: "32rem" }}>
        <div
          style={{
            maxHeight: "calc(100vh - 10rem)",
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {dropdownOpen ? (
            <div className="w-100 d-flex flex-column align-items-stretch px-3 py-2 navbar-search">
              <Input
                autoFocus
                type="text"
                bsSize="sm"
                className="rounded-pill"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && filteredOrgs.length === 1)
                    pickOrganization(filteredOrgs[0].id);
                }}
              />
            </div>
          ) : null}
          {filteredOrgs.map((o) => (
            <DropdownItem
              key={o.id}
              onClick={() => pickOrganization(o.id)}
              className="text-truncate"
            >
              {o.application === "PC" && <FontAwesomeIcon icon={faLink} />}{" "}
              {o.name}
            </DropdownItem>
          ))}
          {filteredOrgs.length == 0 ? (
            <DropdownItem disabled key="no-orgs">
              No organizations
            </DropdownItem>
          ) : null}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withAlertModal(OrgSwitcher);
