import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ClampLines from "react-clamp-lines";
import { Link } from "react-router-dom";

interface AdminPortalTileProps {
  to: string;
  name: string;
  description: string;
  icon: IconProp;
  className?: string;
}

const AdminPortalTile: React.FC<AdminPortalTileProps> = ({
  to,
  name,
  description,
  icon,
  className,
}) => {
  return (
    <Link
      to={to}
      className="portal-tile d-flex align-items-center hover-underline-within"
    >
      <div className="flex-shrink-0 portal-icon">
        <FontAwesomeIcon icon={icon} size="xl" className="color-pop" />
      </div>
      <div className="flex-grow-1">
        <div className="portal-label hover-underline">{name}</div>
        <ClampLines
          id={description}
          text={description}
          lines={4}
          buttons={false}
          className="portal-desc"
        />
      </div>
    </Link>
  );
};

export default AdminPortalTile;
