import {
  ReportSheet,
  exhaustiveCheck,
  isRowsByDate,
  isRowsByDimension,
  isRowsByFinance,
  isRowsByInsightData,
  isRowsByLease,
  isRowsByProperty,
  isRowsByUnit,
} from "@joyhub-integration/shared";
import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import UncontrolledModal from "../../common/modal/UncontrolledModal";
import GenericReportSheetSettings from "./GenericReportSheetSettings";

const AddEditSheetModal: React.FC<{
  add: boolean;
  sheet: ReportSheet;
  setSheet: (s?: ReportSheet) => void;
  onSubmit: () => void;
}> = ({ add, sheet, setSheet, onSubmit }) => {
  const tpe = isRowsByProperty(sheet.rows)
    ? "Property"
    : isRowsByDate(sheet.rows)
      ? "Date"
      : isRowsByDimension(sheet.rows)
        ? "Dimension"
        : isRowsByLease(sheet.rows)
          ? "Lease"
          : isRowsByUnit(sheet.rows)
            ? "Unit"
            : isRowsByFinance(sheet.rows)
              ? "Finance"
              : isRowsByInsightData(sheet.rows)
                ? "Insight Data"
                : exhaustiveCheck(sheet.rows);
  return (
    <UncontrolledModal
      size="lg"
      isOpen={true}
      onClosed={() => setSheet(undefined)}
      onFormSubmit={onSubmit}
    >
      <ModalHeader>
        {add ? `Add ${tpe} Sheet` : `Edit ${tpe} Sheet`}
      </ModalHeader>
      <ModalBody>
        <GenericReportSheetSettings sheet={sheet} setSheet={setSheet} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => setSheet(undefined)}>
          Cancel
        </Button>
        <Button
          color="primary"
          type="submit"
          className="ms-2"
          disabled={!sheet.name}
        >
          Submit
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default AddEditSheetModal;
