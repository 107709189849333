import {
  ColumnFormat,
  ReportDataColumn,
  ReportDataValue,
  formatCellDate,
  formatCellValue,
  formatStringValue,
} from "@joyhub-integration/shared";

import { formatCurrency } from "./currency";

export const formatValue = (
  value: ReportDataValue,
  column: ReportDataColumn,
  format?: ColumnFormat,
) => {
  const numFmt = format?.numFmt ?? column.format?.numFmt;
  let formatted: any;
  if (column.type === "Date") {
    formatted = formatCellDate(value, numFmt);
  } else if (column.type === "String") {
    let formatted = formatStringValue(value);
    if (column.attribute && formatted && formatted !== "") {
      if (
        column.attribute === "capitalization_rate" ||
        column.attribute.includes("user_decimal")
      ) {
        formatted =
          parseFloat(formatStringValue(value) as string).toFixed(2) + "%";
      } else if (
        column.attribute === "purchase_price" ||
        column.attribute.includes("user_money")
      ) {
        formatted = formatCurrency(formatStringValue(value) as string);
      }
    }
    return formatted;
  } else {
    formatted = formatCellValue(value, numFmt);
  }
  return formatted;
};
