import { faDownload, faRedo } from "@fortawesome/pro-light-svg-icons";
import { AdHocInsightHistoryEntity, dateStr } from "@joyhub-integration/shared";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { getAdHocInsightHistories } from "../../../services/adHocInsightService";
import { apiUrl } from "../../../utils/api";
import { dateOf } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";

type HistoryPagePropsType = {
  id: string;
};

const HistoryPage: React.FC<WithAlertModalProps> = ({ onUnexpectedError }) => {
  const params = useParams<HistoryPagePropsType>();
  const [histories, setHistories] =
    useState<Array<AdHocInsightHistoryEntity>>();
  const [selected, setSelected] = useState<AdHocInsightHistoryEntity>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const adHocInsightId = useMemo(() => parseInt(params.id!), [params.id]);

  const downloadFileURL = (historyId: number, type: "input" | "output") =>
    apiUrl(`/adHocInsight/${historyId}/download-${type}`);

  useEffect(() => {
    setLoading(true);
    getAdHocInsightHistories(adHocInsightId)
      .then((result) => {
        setHistories(result);
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [onUnexpectedError, adHocInsightId, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "Download Input File",
      icon: faDownload,
      href: selected ? `${downloadFileURL(selected!.id, "input")}` : undefined,
      className: "jh-btn-primary",
      disabled: !selected,
      download: true,
    },
    {
      label: "Download Output File",
      icon: faDownload,
      href: selected ? `${downloadFileURL(selected!.id, "output")}` : undefined,
      className: "jh-btn-primary",
      disabled: !selected,
      download: true,
    },
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols: Array<KeyValue<AdHocInsightHistoryEntity>> = [
    {
      key: "created",
      title: "Excuted Time",
      toValue: (s) => dateStr(s.created.toString()),
      sortValue: (s) => dateOf(s.created).getTime(),
    },
    {
      key: "status",
      title: "Status",
      toValue: (i) => (i.status ? "Success" : "Failure"),
    },
    { key: "log", title: "Log" },
  ];

  return (
    <LoadilyFadily loaded={loaded} className="jh-page-layout">
      <JhCrumbar
        back
        primary="Ad Hoc Insight History"
        primaryPath="/admin/adhoc-insights"
      />
      <div className="jh-page-content sync-history-admin-page admin-page">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <TableWithSelection<AdHocInsightHistoryEntity>
          selected={selected}
          onSelectedChange={(selected) => setSelected(selected)}
          columns={tableCols}
          rows={histories}
          sortColumn="created"
          sortDirection="desc"
        />
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(HistoryPage);
