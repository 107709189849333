import { Buffer } from "buffer";

import {
  faDownload,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { AccountTreeDto, AccountTreeEntity } from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { useParams } from "react-router-dom";
import { unexpectedError } from "../../../constants";
import axios from "../../../services/axios";
import { apiUrl, axiosConfig, axiosJsonConfig } from "../../../utils/api";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import SubmitButton from "../../common/button/SubmitButton";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import DeleteModal from "../common/DeleteModal";

// somewhat duplicated in financeService

const fetchAccountTrees = async (system: number) =>
  axios
    .get<{
      trees: AccountTreeEntity[];
    }>(apiUrl(`/gl/trees?system=${system}`), axiosConfig)
    .then((res) => res.data.trees);

const fetchAccountTree = async (id: number) =>
  axios
    .get<AccountTreeEntity>(apiUrl(`/gl/trees/${id}`), axiosConfig)
    .then((res) => res.data);

const deleteAccountTree = async (id: number) =>
  axios.delete<void>(apiUrl(`/gl/trees/${id}`), axiosConfig).then(() => void 0);

const createAccountTree = async (tree: AccountTreeDto) =>
  axios
    .post<AccountTreeEntity>(apiUrl(`/gl/trees`), tree, axiosJsonConfig)
    .then((res) => res.data);

const updateAccountTree = async (id: number, tree: AccountTreeDto) =>
  axios
    .put<AccountTreeEntity>(apiUrl(`/gl/trees/${id}`), tree, axiosJsonConfig)
    .then((res) => res.data);

interface AddEditAccountTreeModalProps {
  systemId: number;
  accountTree?: AccountTreeEntity;
  onSubmit: (accountTree: AccountTreeEntity) => void;
  onClose: () => void;
}

const AddEditAccountTreeModal: React.FC<AddEditAccountTreeModalProps> = ({
  systemId,
  accountTree,
  onSubmit,
  onClose,
}) => {
  const [serverError, setServerError] = useState<string>("");
  const [code, setCode] = useState<string>(accountTree?.tree_code ?? "");
  const [name, setName] = useState<string>(accountTree?.tree_name ?? "");
  const [structure, setStructure] = useState<string>();
  const [validationError, setValidationError] = useState<{
    field: string;
    message: string;
  }>();
  const [submitting, setSubmitting] = useState(false);

  function parseFile(file: File) {
    setServerError("");
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      try {
        const data = event.target?.result as ArrayBuffer;
        setStructure(Buffer.from(data).toString("base64"));
        if (validationError?.field === "structure")
          setValidationError(undefined);
      } catch (e) {
        console.log(e);
        setStructure(undefined);
        setValidationError({
          field: "structure",
          message: "Error loading file ",
        });
      }
    });
    reader.readAsArrayBuffer(file);
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!code) {
      setValidationError({ field: "code", message: "Field is required" });
    } else if (!name) {
      setValidationError({ field: "name", message: "Field is required" });
    } else if (!structure) {
      setValidationError({ field: "structure", message: "Field is required" });
    } else {
      setValidationError(undefined);
      const treeDto: AccountTreeDto = {
        system_id: systemId,
        tree_code: code,
        tree_name: name,
        gl_account_format_mask: null,
        structure: structure,
      };
      const promise = accountTree
        ? updateAccountTree(accountTree.id, treeDto)
        : createAccountTree(treeDto);
      setSubmitting(true);
      promise
        .then((accountTree) => onSubmit(accountTree))
        .catch((err) => {
          const response = err.response;
          if (response && response.status === 400 && response.data?.message) {
            setServerError(response.data.message);
          } else {
            setServerError(unexpectedError);
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <UncontrolledModal size="lg" onFormSubmit={onFormSubmit}>
      <ModalHeader toggle={onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          <span>
            {accountTree != null
              ? `Edit ${accountTree.tree_name}`
              : "Add Account Tree"}
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup>
          <Label>Code</Label>
          <Input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            invalid={validationError?.field === "code"}
          />
          {validationError?.field === "code" && validationError?.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Name</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            invalid={validationError?.field === "name"}
          />
          {validationError?.field === "name" && validationError?.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Structure</Label>
          <Input
            id="structure"
            type="file"
            accept=".csv,.xlsx"
            onChange={(e) => parseFile(e.target.files![0])}
            invalid={validationError?.field === "structure"}
          />
          {validationError?.field === "structure" &&
          validationError?.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <SubmitButton color="primary" busy={submitting}>
          Save
        </SubmitButton>
      </ModalFooter>
    </UncontrolledModal>
  );
};

const AccountTreesPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [accountTrees, setAccountTrees] = useState<AccountTreeEntity[]>();
  const [selected, setSelected] = useState<AccountTreeEntity>();
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState<"add" | "edit" | "delete">();
  const params = useParams<{ id: string }>();
  const systemId = parseInt(params.id!);

  useEffect(() => {
    fetchAccountTrees(systemId)
      .then(setAccountTrees)
      .catch(onUnexpectedError)
      .finally(() => {
        setLoaded(true);
      });
  }, [onUnexpectedError, systemId]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "Add Account Tree",
      icon: faPlus,
      className: "jh-btn-primary",
      onClick: () => setModal("add"),
    },
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setModal("edit"),
      tooltip: "Edit Account Tree",
    },
    {
      icon: faDownload,
      tooltip: "Download Account Tree",
      tag: "a",
      className: clsx("btn btn-secondary", !selected && "disabled"),
      href: selected ? apiUrl(`/gl/trees/${selected.id}/download`) : undefined,
      download: true,
    },
    {
      icon: faTrash,
      disabled: !selected,
      onClick: () => setModal("delete"),
      tooltip: "Delete Account Tree",
    },
  ];

  const tableCols: Array<KeyValue<AccountTreeEntity>> = [
    {
      key: "tree_code",
      title: "Code",
    },
    {
      key: "tree_name",
      title: "Name",
    },
  ];

  const onSubmission = (message: string) => {
    fetchAccountTrees(systemId)
      .then((trees) => {
        setAccountTrees(trees);
        setAlert(message, true);
        setModal(undefined);
      })
      .catch(onUnexpectedError);
  };

  return (
    <>
      <ModernCrumbar
        primary="Manage Integrations"
        primaryPath="/admin/integrations"
        secondary="Account Trees"
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar buttonProps={buttonProps} />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<AccountTreeEntity>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={accountTrees}
            sortColumn="started"
            sortDirection="desc"
          />
        </div>
      </LoadilyFadily>
      {modal === "add" ? (
        <AddEditAccountTreeModal
          systemId={systemId}
          onSubmit={(tree: AccountTreeEntity) =>
            onSubmission(`Account tree ${tree.tree_name} created`)
          }
          onClose={() => setModal(undefined)}
        />
      ) : modal === "edit" && selected ? (
        <AddEditAccountTreeModal
          systemId={systemId}
          accountTree={selected}
          onSubmit={(tree: AccountTreeEntity) =>
            onSubmission(`Account tree ${tree.tree_name} updated`)
          }
          onClose={() => setModal(undefined)}
        />
      ) : modal === "delete" && selected ? (
        <DeleteModal<AccountTreeEntity>
          id={selected.id}
          entityName="Account Tree"
          identificationKey="tree_name"
          getEntity={fetchAccountTree}
          deleteEntity={deleteAccountTree}
          onSubmit={(tree: AccountTreeEntity) =>
            onSubmission(`Account tree ${tree.tree_name} deleted`)
          }
          onClose={() => setModal(undefined)}
        />
      ) : null}
    </>
  );
};

export default withAlertModal(AccountTreesPage);
