import { faNotebook, faRedo } from "@fortawesome/pro-light-svg-icons";
import { durationOf } from "@joyhub-integration/shared";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";

import {
  getIntegrationById,
  Integration,
} from "../../../services/integrationsService";
import { getEcsSessionHistory } from "../../../services/ybrServices";
import { dateOf } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import { ModernCrumbar } from "../../layout/ModernCrumbar";

type SyncHistoryParams = {
  id: string;
};

dayjs.extend(calendar);

const EcsSessionHistoryPage: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const params = useParams<SyncHistoryParams>();
  const [sessions, setSessions] = useState<any>();
  const [selected, setSelected] = useState<any>();
  const [integration, setIntegration] = useState<Integration>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const systemId = parseInt(params.id!);
  const navigate = useNavigate();
  const { platform } = useContext(PlatformContext);

  useEffect(() => {
    setLoading(true);
    Promise.all([getEcsSessionHistory(systemId), getIntegrationById(systemId)])
      .then((rvs: [Array<any>, Integration]) => {
        // If I destructure rvs as [a,b], type inference fails, but if I do
        // this it works.  Would love an explanation.
        setSessions(rvs[0].map((s) => ({ id: s.session, ...s }))); // table needs an id field
        setIntegration(rvs[1]);
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [onUnexpectedError, systemId, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "View Events",
      icon: faNotebook,
      onClick: () => navigate(`${selected.session}`),
      className: "jh-btn-primary",
      disabled: !selected,
    },
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols: Array<KeyValue<any>> = [
    {
      key: "session",
      title: "Session ID",
      sortable: false,
    },
    {
      key: "timestamp",
      title: "Started",
      toValue: (s) => {
        return dayjs(s.timestamp).toString();
      },
      sortValue: (s) => dateOf(s.timestamp)?.getTime(),
    },
    {
      key: "finished",
      title: "Duration",
      toValue: (s) => durationOf(s.started, s.finished),
    },
    { key: "tasks", title: "Total Tasks" },
  ];

  return (
    <>
      <ModernCrumbar
        primary="Sync History"
        primaryPath={`/admin/integrations/${systemId}`}
        secondary={`"${integration?.name ?? "?"}" ECS Sync History for ${
          platform?.organization?.name ?? "Unknown org"
        } (${integration?.vendor ?? "?"})`}
      />
      <LoadilyFadily loaded={loaded} className="jh-page-layout">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <div className="jh-page-content pt-0 admin-page page-scroll">
          <TableWithSelection<any>
            selected={selected}
            onSelectedChange={(selected) => setSelected(selected)}
            columns={tableCols}
            rows={sessions}
            sortColumn="timestamp"
            sortDirection="desc"
          />
        </div>
      </LoadilyFadily>
    </>
  );
};

export default withAlertModal(EcsSessionHistoryPage);
