import "./jhCrumbar.css";

import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import clsx from "clsx";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

import ButtonWithIcon from "../common/button/ButtonWithIcon";

interface JhCrumbarProps {
  primary: string;
  primaryPath?: string;
  secondary?: string;
  back?: boolean | (() => void);
  titleRedirect?: string;
  className?: string;
  noShadow?: boolean;
  children?: React.ReactNode;
}

const JhCrumbar: React.FC<JhCrumbarProps> = ({
  primary,
  secondary,
  back,
  children,
  titleRedirect,
  className,
  noShadow,
}) => {
  const navigate = useNavigate();
  const title = secondary ?? primary;
  return (
    <div
      className={
        className
          ? className
          : clsx(
              "jh-crumbar d-flex flex-row flex-wrap",
              !noShadow && "scrolly-box-shadow",
            )
      }
    >
      {!back ? null : (
        <ButtonWithIcon
          icon={faArrowLeft}
          tooltip="Go Back"
          color="link"
          className="py-0 px-2 me-2"
          onClick={() => (typeof back === "boolean" ? navigate(-1) : back())}
          id="go-back"
        />
      )}
      <div className="active jh-crumbs">
        {titleRedirect ? <Link to={titleRedirect}>{title}</Link> : title}
      </div>
      {children}
    </div>
  );
};

export default JhCrumbar;
