import { PropertiesSelection } from "@joyhub-integration/shared";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";

import clsx from "clsx";
import { useBreakpoint } from "../../hooks/useBreakpoint";
import {
  Dashboard,
  propertyDashboardUrl,
} from "../../services/dashboardService";
import {
  asPropertiesQuery,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";

const formatDashboardUrl = (
  dashboard: Dashboard,
  linkPrefix: string,
  selection: PropertiesSelection | undefined,
) => `${linkPrefix}/${dashboard.identifier}?${asPropertiesQuery(selection)}`;

interface DashboardSwitcherProps {
  activeDashboardId?: number;
  dashboards: Dashboard[];
  propertyId: number | undefined;
  manage?: boolean;
  loaded?: boolean;
}

const DashboardSwitcher = ({
  dashboards,
  activeDashboardId,
  manage,
  loaded,
  propertyId,
}: DashboardSwitcherProps) => {
  const [numberToShow, setNumberToShow] = useState(5);
  const [selection] = usePropertiesSelectionQueryParam();

  const linkPrefix =
    propertyId == null ? "/dashboards" : `/properties/${propertyId}/dashboards`;

  const propertyLink = propertyId
    ? propertyDashboardUrl(propertyId)
    : undefined;

  const breakpoint = useBreakpoint();

  useEffect(() => {
    switch (breakpoint) {
      case "xs":
        setNumberToShow(1);
        break;
      case "sm":
        setNumberToShow(2);
        break;
      case "md":
        setNumberToShow(3);
        break;
      default:
        setNumberToShow(5);
        break;
    }
  }, [breakpoint]);

  const actualNumberToShow = numberToShow - (propertyId ? 1 : 0);
  const mainDashboards = dashboards.slice(0, actualNumberToShow);
  const moreDashboards = dashboards.slice(actualNumberToShow);

  const activeMoreDashboard = moreDashboards.find(
    (dashboard) => dashboard.id === activeDashboardId,
  );

  return (
    <ol className="navbar-nav pe-2">
      {loaded && propertyLink ? (
        <NavItem>
          <Link
            className={clsx(
              "nav-link text-truncate",
              activeDashboardId === -1 && "active",
            )}
            to={propertyLink}
          >
            Dashboard
          </Link>
        </NavItem>
      ) : null}
      {mainDashboards.map((dashboard) => (
        <NavItem key={dashboard.id}>
          <Link
            className={clsx(
              "nav-link text-truncate",
              dashboard.id === activeDashboardId && "active",
            )}
            to={formatDashboardUrl(dashboard, linkPrefix, selection)}
          >
            {dashboard.name}
          </Link>
        </NavItem>
      ))}
      {loaded && (
        <UncontrolledDropdown nav active={activeMoreDashboard !== undefined}>
          <DropdownToggle
            nav
            caret
            style={
              breakpoint === "xs" || breakpoint === "sm"
                ? {
                    maxWidth: 150,
                  }
                : undefined
            }
            className={clsx(
              "nav-link text-truncate",
              manage || activeMoreDashboard ? "active" : null,
            )}
          >
            {manage
              ? "Manage Dashboards"
              : activeMoreDashboard
                ? activeMoreDashboard.name
                : "More"}
          </DropdownToggle>
          <DropdownMenu end style={{ maxHeight: "50vh", overflowY: "auto" }}>
            {moreDashboards.map((dashboard) => (
              <DropdownItem
                key={dashboard.id}
                disabled={dashboard.id === activeDashboardId}
                tag={Link}
                to={formatDashboardUrl(dashboard, linkPrefix, selection)}
              >
                {dashboard.name}
              </DropdownItem>
            ))}
            {moreDashboards.length ? <DropdownItem divider /> : null}
            <DropdownItem
              tag={Link}
              to={
                linkPrefix.startsWith("/dash")
                  ? "/dashboards"
                  : "/properties/dashboards"
              }
            >
              Manage Dashboards
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </ol>
  );
};

export default DashboardSwitcher;
