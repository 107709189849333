import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

// Component to handle session-related errors; set state and redirect to the homepage
// without leaving the session route in the browser history. In later iterations this
// will extract error details from query parameters per the OAuth spec.

type SessionErrorProps = {
  setError: (error: string) => void;
  cause: string;
};

const SessionError: React.FC<SessionErrorProps> = ({ setError, cause }) => {
  console.log(`Session error: ${cause}`);
  useEffect(() => setError(cause));
  return <Navigate to="/" replace={true} />;
};

export default SessionError;
