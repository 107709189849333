export const ExcelNumberFormats = {
  Integer: "#,##0",
  DecimalTenths: "#,##0.0",
  DecimalHundredths: "#,##0.00",
  DeltaInteger: "+#,##0;-#,##0;0",
  Dollars: "$#,##0",
  DollarsAccounting: "$#,##0;$(#,##0);$0",
  DollarsCents: "$#,##0.00",
  DollarsCentsAccounting: "$#,##0.00;$(#,##0.00);$0.00",
  DeltaDollars: "+$#,##0;-$#,##0;$0",
  DeltaDollarsCents: "+$#,##0.00;-$#,##0.00;$0",
  PercentTenths: "0.0%",
  DeltaPercentTenths: "+0.0%;-0.0%;0.0%",
  PlainInteger: "0",
  Unknown: undefined,
} as const;

export type ExcelNumberFormat = keyof typeof ExcelNumberFormats;

// In the future we may support extended accounting formats:
// For example _($* #,##0.00_);_($* (#,##0.00);_($* "-"??_);_(@_)
// Positive:  $   1,234.56
// Negative:  $  (1,234.56)
// Zero:      $       -
// Text:              text
export const ExcelNumberFormatRE =
  /^(?<plus>[+])?(?<dollars>\$)?(?<commas>[#,]+)?0(?:\.(?<decimal>0+))?(?<percent>%)?(?:"(?<suffix>[^"]+)")?>?(?<minus>;\$?(?<minusSign>[-(])[^;]+(?<zero>;\$?(?<zeroSign>[+])?[^;]+(?:;.*)?)?)?$/;

// https://tc39.es/ecma402/#sec-intl.numberformat.prototype.format
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
interface ExtendedNumberFormatOptions
  extends Omit<Intl.NumberFormatOptions, "signDisplay"> {
  currencySign?: "accounting" | "standard";
  signDisplay?: "auto" | "never" | "always" | "exceptZero";
}

// So the browser will do what the browser will do for accounting format.. You can't use
// accounting format without a currency sign, and it insists on ($1,000) vs $(1,000) for
// negative values
const excelNumberFormatter = (fmt: string): ((value: number) => string) => {
  const match = fmt.match(ExcelNumberFormatRE);
  if (!match?.groups) return (value) => value.toString();
  const {
    plus,
    dollars,
    commas,
    decimal,
    percent,
    suffix,
    minusSign,
    zero,
    zeroSign,
  } = match.groups;
  const options: ExtendedNumberFormatOptions = {
    style: percent ? "percent" : dollars ? "currency" : "decimal",
    currency: dollars ? "USD" : undefined,
    currencyDisplay: dollars ? "narrowSymbol" : undefined,
    currencySign: dollars && minusSign === "(" ? "accounting" : undefined,
    signDisplay: plus
      ? zero && !zeroSign
        ? "exceptZero"
        : "always"
      : undefined,
    useGrouping: commas != null,
    minimumFractionDigits: decimal?.length ?? 0,
    maximumFractionDigits: decimal?.length ?? 0,
  };
  const numberFormat = new Intl.NumberFormat(
    undefined,
    options as Intl.NumberFormatOptions,
  );
  return (value) => numberFormat.format(value) + (suffix ?? "");
};

const formatters: Record<string, (value: number) => string> = {};

export const excelFormatNumber = (value: number, format: string) =>
  (formatters[format] ?? (formatters[format] = excelNumberFormatter(format)))(
    value,
  ); // 😍 so elegant

const DateFmtRE = /(m+|d+|q+|y+|[^mdqy]+)/g;

// https://support.microsoft.com/en-us/office/format-a-date-the-way-you-want-8e10019e-d5d8-47a1-ba95-db95123d273e
// https://tc39.es/ecma402/#sec-datetimeformat-abstracts
const DateFmtMap: Record<string, object> = {
  m: { month: "numeric" }, // 1|10
  mm: { month: "2-digit" }, // 01|10
  mmm: { month: "short" }, // Jan
  mmmm: { month: "long" }, // January
  mmmmm: { month: "narrow" }, // J|F|M
  q: { quarter: "numeric" }, // 1, 2, 3, 4
  d: { day: "numeric" }, // 1|10
  dd: { day: "2-digit" }, // 01|10
  ddd: { weekday: "short" }, // Mon|Tue
  dddd: { weekday: "long" }, // Monday|Tuesday
  yy: { year: "2-digit" },
  yyyy: { year: "numeric" },
};

export const excelFormatDate = (date: Date, dateFmt: string) => {
  const matches = dateFmt.match(DateFmtRE);
  if (matches == null) return date.toString();
  return matches
    .map((part) => {
      if (part === "q") {
        return Math.floor((date.getMonth() + 3) / 3); // Quarter
      } else {
        const options = DateFmtMap[part];
        return options == null
          ? part
          : Intl.DateTimeFormat(undefined, options).format(date);
      }
    })
    .join("");
};
