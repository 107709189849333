import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import PlatformContext from "../components/app/PlatformContext";

export const usePropertyNote = () => {
  const { propertyId: propertyIdParam } = useParams();
  const { platform, setProperties: setPropertiesInContext } =
    useContext(PlatformContext);

  const [propertyId, setPropertyId] = useState<number | undefined>();
  const [propertyNote, setPropertyNote] = useState<string>("");

  const { embedInfo, propertiesMap } = platform!;

  const updatePropertyNoteInContext = useCallback(
    (note: string) => {
      const allProperties = Object.values(propertiesMap);

      setPropertiesInContext(
        allProperties.map((property) =>
          property.id === propertyId ? { ...property, note } : property,
        ),
      );
    },
    [propertiesMap, propertyId, setPropertiesInContext],
  );

  useEffect(() => {
    if (!platform) return;

    let propertyIdValue;
    if (embedInfo)
      propertyIdValue =
        embedInfo.propertyId === null ? undefined : embedInfo.propertyId;
    else
      propertyIdValue =
        propertyIdParam == null ? undefined : parseInt(propertyIdParam);

    setPropertyId(propertyIdValue);
  }, [platform, embedInfo, propertyIdParam, setPropertyId]);

  useEffect(() => {
    if (propertyId) {
      setPropertyNote(propertiesMap[propertyId]?.note ?? "");
    }
  }, [propertyId, propertiesMap, setPropertyNote]);

  return {
    propertyId,
    isPropertyDashboard: !!propertyId,
    propertyNote,
    updatePropertyNoteInContext,
  };
};
