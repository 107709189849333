import "./reportScreen.css";

import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkPermission, DashboardKindEnum } from "@joyhub-integration/shared";
import clsx from "clsx";
import { filter, groupBy } from "lodash";
import { orderBy } from "natural-orderby";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  NavItem,
  Table,
} from "reactstrap";
import { useQueryParam } from "use-query-params";

import { Report } from "../../../services/models";
import {
  deleteReport,
  getOrgReports,
  GetReportResult,
  getSharedReports,
  ReportSection,
} from "../../../services/reportsService";
import { setToggle } from "../../../utils/set";
import { deduplicateInsensitively } from "../../../utils/tagUtils";
import DeleteModal from "../../admin/common/DeleteModal";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import { ModernCrumbar } from "../../layout/ModernCrumbar";
import AddReportModal from ".././AddReportModal";
import { NewReportButton } from ".././NewReportButton";
import ReportCard from "./ReportCard";
import ReportHeaderColumn, { ReportSortValue } from "./ReportHeaderColumn";
import { ReportScreenEmpty } from "./ReportScreenEmpty";

const reportMatcher = (filter: string) => (report: Report) => {
  return (
    report.name.toLowerCase().includes(filter) ||
    report.category.find((category) =>
      category.toLowerCase().includes(filter),
    ) !== undefined
  );
};

const Uncategorized = "Uncategorized";

const ReportScreen: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const { section } = useParams<{ section: ReportSection }>();
  const [reports, setReports] = useState<Array<Report>>([]);
  const [loaded, setLoaded] = useState(false);
  const [addReport, setAddReport] = useState(false);
  const [deletingReport, setDeletingReport] = useState<Report>();
  const [category0, setCategory] = useQueryParam<string | undefined>(
    "category",
  );
  const [filterValue0, setFilterValue] = useQueryParam<string | undefined>(
    "filter",
  );
  const [sortValue, setSortValue] = useState<ReportSortValue>([
    "edited",
    "desc",
  ]);
  const { organization_role, superAdmin } =
    useContext(PlatformContext).platform!;

  const navigate = useNavigate();

  const filterValue = filterValue0 ?? "";
  const category = useMemo(
    () => new Set(category0?.split(",") ?? []),
    [category0],
  );

  // hide zendesk widget until we leave this page
  useEffect(() => {
    window.zE?.("messenger:set", "zIndex", -5000);
    return () => {
      window.zE?.("messenger:set", "zIndex", 5000);
    };
  }, []);

  const fetchReports = useCallback(
    async function fetchReports() {
      if (section === "library") {
        return getSharedReports()
          .then((reports) => setReports(orderBy(reports, ...sortValue)))
          .catch(onUnexpectedError);
      } else {
        return getOrgReports()
          .then((reports) => setReports(orderBy(reports, ...sortValue)))
          .catch(onUnexpectedError);
      }
    },
    [sortValue, onUnexpectedError, section],
  );

  const categories = useMemo(
    () =>
      deduplicateInsensitively(
        reports
          .flatMap((report) =>
            report.category?.length ? report.category : [Uncategorized],
          )
          .sort(),
      ),
    [reports],
  );

  const groupedReports = useMemo<Record<string, Report[]>>(
    () => ({
      ...groupBy(
        orderBy(
          filter(reports, reportMatcher(filterValue.toLowerCase())).filter(
            (report) =>
              !category.size ||
              (report.category?.length
                ? report.category.some((cat) => category.has(cat.toLowerCase()))
                : category.has(Uncategorized.toLowerCase())),
          ),
          ...sortValue,
        ),
        (r) => (!category.size ? r : (r.category?.[0] ?? Uncategorized)),
      ),
    }),
    [reports, filterValue, sortValue, category],
  );

  const toggleSortOrder = (field: string) => {
    const currentField = sortValue[0];
    const isDate = field === "edited" || field === "created";
    setSortValue([
      field as keyof GetReportResult,
      field === currentField
        ? sortValue[1] === "asc"
          ? "desc"
          : "asc"
        : isDate
          ? "desc"
          : "asc",
    ]);
  };

  const onCreateReport = () => {
    if (superAdmin) {
      setAddReport(true);
    } else {
      navigate(
        `/reports/${section}/_new?kind=${DashboardKindEnum.GenericReport}`,
      );
    }
  };

  const onDeleteReport = () =>
    fetchReports().then(() => {
      setAlert(`Deleted report ${deletingReport!.name}`, true);
      setDeletingReport(undefined);
    });

  useEffect(() => {
    setLoaded(false);
    fetchReports().then(() => {
      setLoaded(true);
    });
  }, [fetchReports]);

  // If you have a small number of shortish categories then put it on one line
  const noWrap = useMemo(
    () => categories.length < 6 && categories.join().length <= 48,
    [categories],
  );

  return (
    <>
      <ModernCrumbar>
        <ol className="navbar-nav">
          <NavItem>
            <Link
              className={clsx("nav-link", section === "my" && "active")}
              to="/reports/my"
            >
              My Reports
            </Link>
          </NavItem>
          <NavItem>
            <Link
              className={clsx("nav-link", section === "library" && "active")}
              to="/reports/library"
            >
              Library
            </Link>
          </NavItem>
        </ol>
        {reports.length > 0 &&
        checkPermission("reports", "create", organization_role) ? (
          <NewReportButton onClick={onCreateReport} />
        ) : null}
      </ModernCrumbar>
      <LoadilyFadily
        className="report-screen-main jh-page-layout"
        loaded={loaded}
      >
        {reports.length > 0 ? (
          <>
            <div
              className={clsx(
                "flex-row px-4 py-3 flex-wrap  align-items-center reports-cluster jh-crumbar-like",
                noWrap && "flex-lg-nowrap",
              )}
            >
              <InputGroup
                className={clsx(
                  "justify-content-center rounded-0",
                  noWrap && "me-lg-4",
                )}
              >
                <InputGroupText className="bg-white px-3 rounded-0">
                  <FontAwesomeIcon size="sm" icon={faSearch} />
                </InputGroupText>
                <Input
                  placeholder="Find a report"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value || undefined)}
                  className="ps-0 rounded-0"
                  style={{ borderLeft: "none", height: "38px" }}
                />
              </InputGroup>
              <div
                className={clsx("flex-row justify-content-center flex-wrap", {
                  "flex-lg-nowrap": noWrap,
                })}
              >
                {categories.map((cat) => (
                  <Button
                    key={cat}
                    className={clsx("ms-2 ms-0-first text-nowrap mt-2", {
                      "mt-lg-0": noWrap,
                    })}
                    outline={!category.has(cat.toLowerCase())}
                    onClick={() =>
                      setCategory(
                        Array.from(setToggle(category, cat.toLowerCase())).join(
                          ",",
                        ) || undefined,
                      )
                    }
                  >
                    {cat}
                  </Button>
                ))}
              </div>
            </div>
            <div className="mx-4 pb-3">
              <Table hover borderless className="reports-table">
                <thead>
                  <tr>
                    <ReportHeaderColumn
                      label={
                        (section === "library" ? "Template" : "Report") +
                        " Name"
                      }
                      field="name"
                      sortValue={sortValue}
                      onSortToggle={toggleSortOrder}
                      isFirst
                    />

                    {section === "my" ? (
                      <ReportHeaderColumn
                        label="Modified By"
                        field="editor_name"
                        sortValue={sortValue}
                        onSortToggle={toggleSortOrder}
                      />
                    ) : null}

                    <ReportHeaderColumn
                      label="Created On"
                      field="created"
                      sortValue={sortValue}
                      onSortToggle={toggleSortOrder}
                    />

                    <ReportHeaderColumn
                      label="Updated On"
                      field="edited"
                      sortValue={sortValue}
                      onSortToggle={toggleSortOrder}
                    />
                    <th>Category</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!Object.keys(groupedReports).length ? (
                    <tr>
                      <td className="text-muted ps-4" colSpan={6}>
                        No matching reports.
                      </td>
                    </tr>
                  ) : (
                    Object.entries(groupedReports).map(([_, reports]) =>
                      orderBy(reports, ...sortValue).map((report) => (
                        <ReportCard
                          key={report.id}
                          report={report}
                          section={section!}
                          onDelete={() => setDeletingReport(report)}
                        />
                      )),
                    )
                  )}
                </tbody>
              </Table>
            </div>
          </>
        ) : (
          <ReportScreenEmpty section={section!} action={onCreateReport} />
        )}
      </LoadilyFadily>
      {deletingReport ? (
        <DeleteModal<Report>
          id={deletingReport.id}
          entityName="Report"
          identificationKey="name"
          getEntity={() => deletingReport}
          deleteEntity={() => deleteReport(deletingReport.id)}
          onClose={() => setDeletingReport(undefined)}
          onSubmit={onDeleteReport}
          blurb={`There are currently ${deletingReport.scheduledEmailCount} emails scheduled for this report.`}
        />
      ) : null}
      {addReport && superAdmin ? (
        <AddReportModal toggle={() => setAddReport(false)} section={section!} />
      ) : null}
    </>
  );
};
export default withAlertModal(ReportScreen);
