import "./App.scss";

import { DashboardKindEnum } from "@joyhub-integration/shared";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import usePendo from "../../hooks/usePendo";
import useZendesk from "../../hooks/useZendesk";
import {
  applicationName,
  environment,
  environmentName,
  getPlatform,
  Platform,
  warmDatabase,
} from "../../services/platformService";
import { Property } from "../../services/propertiesService";
import {
  getSessionOrganization,
  setSessionOrganization,
} from "../../services/sessionOrganization";
import { keepSessionAlive, zendeskLogin } from "../../services/sessionService";
import { apiReady } from "../../utils/api";
import { arrayToDict } from "../../utils/arrayToDict";
import AdHocInsightsAdminPage from "../admin/adHocInsights/AdHocInsightsAdminPage";
import HistoryPage from "../admin/adHocInsights/HistoryPage";
import AdminPortal from "../admin/adminPortal/AdminPortal";
import AppearanceSettings from "../admin/appearanceSettings";
import CloudHealthPage from "../admin/cloud/CloudHealthPage";
import CommunicationsLogPage from "../admin/communicationsLog/CommunicationsLogPage";
import FinancialImportsAdminPage from "../admin/financialImports/FinancialImportsAdminPage";
import GoalsAdminPage from "../admin/goals/GoalsAdminPage";
import InsightsAdminPage from "../admin/insights/InsightsAdminPage";
import AccountTreesPage from "../admin/integrations/AccountTrees";
import ComputeHistoryPage from "../admin/integrations/ComputeHistoryPage";
import EcsEvents from "../admin/integrations/EcsEvents";
import EcsSyncHistory from "../admin/integrations/EcsSyncHistory";
import ImportHistoryPage from "../admin/integrations/ImportHistoryPage";
import IntegrationsAdminPage from "../admin/integrations/Integrations";
import SyncFilesPage from "../admin/integrations/SyncFiles";
import SyncHistoryPage from "../admin/integrations/SyncHistory";
import TransferUsersPage from "../admin/integrations/TransferUsersPage";
import ManageFinancials from "../admin/manageFinancials";
import CreateOrganizationPage from "../admin/organization/CreateOrganizationPage";
import ManageOrganizationPage from "../admin/organization/ManageOrganizationPage";
import Properties from "../admin/properties/Properties";
import PropertyConnect from "../admin/propertyConnect/PropertyConnect";
import RolesAdminPage from "../admin/roles/RolesAdminPage";
import UsersAdminPage from "../admin/users/UsersAdminPage";
import RegionalDirectorDashboard from "../communityDirector/RegionalDirectorDashboard";
import Craport from "../craport/Craport";
import Dashboard from "../dashboard/Dashboard";
import DashboardInsightScreen from "../dashboard/dashboardInsightScreen/DashboardInsight";
import DashboardScreen from "../dashboard/DashboardScreen";
import Error from "../error/Error";
import FinanceScreen from "../finance/FinanceScreen";
import InsightDetailScreen from "../insightsLibrary/InsightDetailScreen";
import InsightsDataScreen from "../insightsLibrary/InsightsDataScreen";
import Layout from "../layout/Layout";
import ReportScreen from "../report/ReportScreen/ReportScreen";
import LoggedIn from "../session/LoggedIn";
import LoggedOut from "../session/LoggedOut";
import Login from "../session/Login";
import OrganizationChange from "../session/OrganizationChange";
import SessionError from "../session/SessionError";
import Unsubscribe from "../unsubscribe/Unsubscribe";
import Loading from "./Loading";
import PlatformContext from "./PlatformContext";

const AddEditReport = React.lazy(() => import("../report/AddEditReport"));

declare global {
  // analytics injected by script in index.html
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;

    zE(context: string, fnName: string): void;

    zE(context: string, fnName: string, amt: number): void;

    zE(
      context: string,
      fnName: string,
      loadJWTFn: (callback: (str: String) => void) => void,
    ): void;
  }
}

const isProd = environment === "Prod";

const PortfolioDashboards = () => (
  <DashboardScreen kind={DashboardKindEnum.Dashboard} />
);
const PropertyDashboards = () => (
  <DashboardScreen kind={DashboardKindEnum.PropertyDashboard} />
);

const reportsPath = `/reports/:section`;

function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>();
  const [loggedOut, setLoggedOut] = useState(false);
  const [platform, setPlatform] = useState<Platform>();
  const [warm, setWarm] = useState<boolean | undefined>(isProd || undefined);
  const [pathName, setPathName] = useState<string>();
  const [analyticsLoaded, setAnalyticsLoaded] = useState<boolean>(false);
  const location = useLocation();

  const nonEmbedded = !!platform && !platform.embedInfo;

  const zendeskKey = process.env.REACT_APP_ZENDESK_KEY as string;
  useZendesk(nonEmbedded ? zendeskKey : undefined);

  usePendo(platform);

  useEffect(() => {
    if (platform?.person.id) {
      window.zE?.("messenger", "logoutUser"); // clear any fields / messages from anonymous login session
      window.zE?.(
        "messenger",
        "loginUser",
        (callback: (str: String) => void) => {
          // log into zendesk if we have a user - should only happen once per application visit
          zendeskLogin().then((e) => {
            callback(e);
          });
        },
      );
      /**
       * NOTE: not strictly sure this is necessary with the addition of "logoutUser" above, but just in case
       *
       * "beforeunload" almost never happens - basically just when we logout (or switch orgs)
       * zendesk seems happier when "close" has been called *before* we arrive at the logout/login page, so do this
       *
       * yes, this means when the user refreshes the page, any chat will be minimized, but I find this fine
       */
      window.addEventListener("beforeunload", () => {
        window.zE?.("messenger", "close");
      });

      pendo.initialize({
        visitor: {
          // Pendo requires a unique ID for each visitor. This ID should be unique across all environments.
          id: isProd
            ? platform.person.id.toString()
            : `${environmentName}_${platform.person.id.toString()}`,
          email: platform.person.email,
          name: platform.person.name,
          type: platform.organization_role,
        },
        account: {
          // Pendo requires a unique ID for each account. This ID should be unique across all environments.
          id: isProd
            ? platform.organization.id.toString()
            : `${environmentName}_${platform.organization.id.toString()}`,
          name: platform.organization.name,
          type: platform.organization.application,
        },
      });
    }
  }, [
    platform?.organization.application,
    platform?.organization.id,
    platform?.organization.name,
    platform?.organization_role,
    platform?.person.email,
    platform?.person.id,
    platform?.person.name,
  ]);

  useEffect(() => {
    if (!analyticsLoaded) {
      try {
        window.analytics.load(process.env.REACT_APP_SEGMENT_API_KEY as string);
      } catch (e) {
        console.error("Unable to load to segment analytics", e);
      }
      setAnalyticsLoaded(true);
    }
  }, [analyticsLoaded]);

  useEffect(() => {
    if (analyticsLoaded && location.pathname !== pathName) {
      try {
        window.analytics.page();
      } catch (e) {
        console.error("Unable to fire segment page event.", e);
      }
      setPathName(pathName);
    }
  }, [location, pathName, analyticsLoaded]);

  // If the user somehow continues to use the application without touching the server we should
  // still keep their session alive.
  useEffect(() => {
    if (platform != null) keepSessionAlive();
  }, [location, platform]);

  useEffect(() => {
    if (!warm) {
      const awaitWarm = () =>
        warmDatabase()
          .then((isWarm) => {
            setWarm(isWarm);
            if (!isWarm) setTimeout(awaitWarm, 15000);
          })
          .catch(() => setError("A database warming error occurred."));
      apiReady.then(awaitWarm).then(() => {});
    }
  }, [warm]);

  const unsubscribe = location.pathname.startsWith("/Unsubscribe/");

  useEffect(() => {
    if (warm && unsubscribe) {
      setLoading(false);
    } else if (warm && !platform) {
      getPlatform()
        .then((platformRes) => setPlatform(platformRes))
        .catch((e) => {
          console.log(e);
          // 401 means not logged in which will go through the login screen instead
          if (e?.response?.status !== 401) {
            setError("A platform loading error occurred.");
          }
        })
        .finally(() => setLoading(false));
    }
  }, [warm, platform, unsubscribe]);

  const updatePlatform = useCallback(
    (update: Partial<Platform>) =>
      setPlatform((platform) =>
        platform ? { ...platform, ...update } : platform,
      ),
    [],
  );

  const setProperties = useCallback(
    (properties: Property[]) =>
      updatePlatform({
        propertiesMap: arrayToDict(properties, (p) => p.id),
      }),
    [updatePlatform],
  );

  const goHome = () => {
    setError(undefined);
    setSessionOrganization(null);
    document.location.href = "/"; // let everything reload
  };

  const title = useMemo(() => {
    const app = applicationName(platform?.organization.application);
    return platform ? `${platform.organization.name} - ${app}` : app;
  }, [platform]);

  // This detects when you reload a tab after logging into a different organization in another tab.
  const sessionOrganization = getSessionOrganization();
  const isSession = location.pathname.startsWith("/session/");
  const organizationChange =
    platform != null &&
    sessionOrganization != null &&
    platform.organization.id !== sessionOrganization &&
    !isSession;

  return error ? (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Error goHome={goHome} cause={error} />
    </>
  ) : loading || !analyticsLoaded ? (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Loading joyhubLogo />
      {isProd ? null : (
        <span
          role="img"
          aria-label="warming"
          className={`warming ${warm === false ? "warm-up" : "warm-down"}`}
        >
          🔥
        </span>
      )}
    </>
  ) : unsubscribe ? (
    <>
      <Unsubscribe />
    </>
  ) : organizationChange ? (
    <OrganizationChange goHome={goHome} platform={platform!} />
  ) : (
    <PlatformContext.Provider
      value={{ platform, setPlatform, setProperties, updatePlatform }}
    >
      <Layout platform={isSession ? undefined : platform}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        {!platform ? (
          <Routes>
            <Route
              path="/session/loggedOut"
              element={<LoggedOut setLoggedOut={setLoggedOut} />}
            />
            <Route
              path="/session/loggedIn"
              element={
                <SessionError
                  setError={setError}
                  cause="Logged in without platform."
                />
              }
            />
            <Route
              path="/session/error"
              element={
                <SessionError
                  setError={setError}
                  cause={
                    new URLSearchParams(location.search).get("error") ??
                    "No platform session error."
                  }
                />
              }
            />
            <Route path="/*" element={<Login loggedOut={loggedOut} />} />
          </Routes>
        ) : (
          <Suspense fallback={<Loading />}>
            <Routes key={platform.organization.id}>
              <Route path="/session/loggedIn" element={<LoggedIn />} />
              <Route
                path="/session/loggedOut"
                element={
                  <SessionError
                    setError={setError}
                    cause="Logged out with platform."
                  />
                }
              />
              <Route
                path="/session/error"
                element={
                  <SessionError
                    setError={setError}
                    cause="With platform session error."
                  />
                }
              />

              {platform.organization.application === "BI" ? (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route
                    path="/insights/data"
                    element={<InsightsDataScreen />}
                  />
                  <Route
                    path="/insights/:id"
                    element={<InsightDetailScreen />}
                  />
                  <Route path="/dashboards" element={<PortfolioDashboards />} />
                  <Route path="/dashboards/:id" element={<Dashboard />} />
                  <Route
                    path="/dashboards/:id/dashboard_insight/:dashboardInsightId"
                    element={<DashboardInsightScreen />}
                  />
                  <Route path={`${reportsPath}`} element={<ReportScreen />} />
                  <Route
                    path="/reports/library/finance"
                    element={<FinanceScreen />}
                  />
                  <Route
                    path="/reports/finance/:tree/:book/:period"
                    element={<FinanceScreen />}
                  />
                  <Route
                    path={`${reportsPath}/_new`}
                    element={<AddEditReport />}
                  />
                  <Route
                    path={`${reportsPath}/:report`}
                    element={<Craport />}
                  />
                  <Route
                    path={`${reportsPath}/:report/edit`}
                    element={<AddEditReport />}
                  />
                  <Route
                    path="/properties"
                    element={<RegionalDirectorDashboard />}
                  />
                  <Route
                    path="/properties/dashboards"
                    element={<PropertyDashboards />}
                  />
                  <Route
                    path="/properties/:propertyId/dashboards/:id"
                    element={<Dashboard />}
                  />
                  <Route
                    path="/properties/:propertyId/dashboards/:id/dashboard_insight/:dashboardInsightId"
                    element={<DashboardInsightScreen />}
                  />
                  {platform.embedInfo ? (
                    <Route
                      path="/embed/*"
                      element={
                        platform.embedInfo?.kind === "dashboard" ? (
                          <Dashboard />
                        ) : platform.embedInfo?.kind === "report" ? (
                          <Craport />
                        ) : null
                      }
                    />
                  ) : null}
                  {platform.admin ? (
                    <>
                      <Route path="/admin" element={<AdminPortal />} />
                      <Route path="/admin/goals" element={<GoalsAdminPage />} />
                      <Route
                        path="/admin/financials/import"
                        element={<FinancialImportsAdminPage />}
                      />
                      <Route
                        path="/admin/insights"
                        element={<InsightsAdminPage />}
                      />
                      <Route
                        path="/admin/integrations"
                        element={<IntegrationsAdminPage />}
                      />
                      <Route
                        path="/admin/integrations/:id"
                        element={<SyncHistoryPage />}
                      />
                      <Route
                        path="/admin/integrations/:id/computeHistory"
                        element={<ComputeHistoryPage />}
                      />
                      <Route
                        path="/admin/integrations/:id/files/:sync"
                        element={<SyncFilesPage />}
                      />
                      <Route
                        path="/admin/integrations/:systemId/syncs/:syncId/files/:importFileId/imports"
                        element={<ImportHistoryPage />}
                      />
                      <Route
                        path="/admin/integrations/:id/transferUsers"
                        element={<TransferUsersPage />}
                      />
                      <Route
                        path="/admin/integrations/:id/trees"
                        element={<AccountTreesPage />}
                      />
                      <Route
                        path="/admin/properties"
                        element={<Properties />}
                      />
                      <Route
                        path="/admin/property-access"
                        element={<RolesAdminPage />}
                      />
                      <Route path="/admin/users" element={<UsersAdminPage />} />
                      <Route
                        path="/admin/appearance-settings"
                        element={<AppearanceSettings />}
                      />
                      {platform.superAdmin ? (
                        <>
                          <Route
                            path="/admin/organization"
                            element={<ManageOrganizationPage />}
                          />
                          <Route
                            path="/admin/create"
                            element={<CreateOrganizationPage />}
                          />
                          <Route
                            path="/admin/cloud"
                            element={<CloudHealthPage />}
                          />
                          <Route
                            path="/admin/financials/manage"
                            element={<ManageFinancials />}
                          />
                          <Route
                            path="/admin/communications"
                            element={<CommunicationsLogPage />}
                          />
                          <Route
                            path="/admin/integrations/:id/ecsSync"
                            element={<EcsSyncHistory />}
                          />
                          <Route
                            path="/admin/integrations/:id/ecsSync/:session"
                            element={<EcsEvents />}
                          />
                          <Route
                            path="/admin/adhoc-insights"
                            element={<AdHocInsightsAdminPage />}
                          />
                          <Route
                            path="/admin/adhoc-insights/:id"
                            element={<HistoryPage />}
                          />
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
              {platform.organization.application === "PC" ? (
                <>
                  <Route path="/" element={<PropertyConnect />} />
                  <Route path="/users" element={<UsersAdminPage />} />
                  <Route
                    path="/admin/integrations"
                    element={<IntegrationsAdminPage />}
                  />
                  <Route
                    path="/admin/integrations/:id"
                    element={<SyncHistoryPage />}
                  />
                  <Route
                    path="/admin/integrations/:id/files/:sync"
                    element={<SyncFilesPage />}
                  />
                  <Route
                    path="/admin/integrations/:id/transferUsers"
                    element={<TransferUsersPage />}
                  />
                  {platform.superAdmin ? (
                    <Route
                      path="/admin/organization"
                      element={<ManageOrganizationPage />}
                    />
                  ) : null}
                </>
              ) : null}
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        )}
      </Layout>
    </PlatformContext.Provider>
  );
}

export default App;
