import "./connectionCard.css";

import { faCircle, faPencilAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import { dateToString } from "../../../utils/date";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import vendorImages from "../integrations/vendorImages";

const daysAgo = (date: Date) =>
  (new Date().getTime() - date.getTime()) / 24 / 60 / 60 / 1000;

const syncColor = (active: boolean, lastSynced?: Date) =>
  !active
    ? "#E14C35A0"
    : lastSynced == null
      ? "#E19735A0"
      : daysAgo(lastSynced) > 2
        ? "#C1B135A0"
        : "#629922A0";

interface ConnectionCardProps {
  id: number;
  name: string;
  vendorType: string;
  dataSyncActive: boolean;
  lastSynced?: Date;
  showEdit: () => void;
}

const ConnectionCard: React.FC<ConnectionCardProps> = ({
  id,
  name,
  dataSyncActive,
  lastSynced,
  vendorType,
  showEdit,
}) => {
  const buttonProps: ButtonWithIconProps[] = [
    {
      icon: faPencilAlt,
      onClick: showEdit,
      popoverProps: {
        text: "Edit Integration",
        placement: "bottom",
        container: `pc-${id}`,
      },
    },
  ];

  return (
    <Card id={`pc-${id}`} className="pc-connection-card">
      <CardBody
        tag={Link}
        style={{ textDecoration: "none" }}
        to={`/admin/integrations/${id}`}
      >
        <div className="pc-connection-card-header">{name}</div>
        <div className="mt-4">
          {lastSynced
            ? `Last sync on ${dateToString(lastSynced)}.`
            : "Unsynchronized."}
        </div>
        <div className="mt-4 d-flex flex-row align-items-center">
          <FontAwesomeIcon
            icon={faCircle}
            size="sm"
            color={syncColor(dataSyncActive, lastSynced)}
          />
          <img src={vendorImages[vendorType]} alt={vendorType} />
        </div>
      </CardBody>
      <ActionBar
        style={{ position: "absolute", top: "1rem", right: 0 }}
        buttonProps={buttonProps}
      />
    </Card>
  );
};

export default ConnectionCard;
