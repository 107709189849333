import React from "react";

import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";

export const ModernNavlink: React.FC<
  {
    to: string;
    prefix?: string;
  } & React.PropsWithChildren
> = ({ to, prefix, children }) => {
  const location = useLocation();

  const active: boolean =
    (prefix && location.pathname.startsWith(prefix)) ||
    location.pathname === to;

  return (
    <Link to={to} className={clsx("nav-link", active && "active")}>
      {children}
    </Link>
  );
};
